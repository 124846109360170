.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  color: #eefbfb;
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  padding: 4rem 2rem 4rem 2rem;
  max-width: 700px;
  margin: auto;
  height: 100%;
}

.button {
  background-color: #4da8da;
  color: #eefbfb;
  border: none;
  border-radius: 4px;
  padding: 1rem 2rem 1rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.button:disabled {
  background-color: #b2bdc5;
  cursor: not-allowed;
}

.button:disabled:hover {
  background-color: #b2bdc5;
  cursor: not-allowed;
}

.button:hover {
  background-color: #007cc7;
}

.subtext {
  font-size: 1rem;
  color: #b2bdc5;
  margin: 0;
}

.title {
  text-align: center;
}
