.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  margin-bottom: 1rem;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
