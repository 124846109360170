.profile-pic {
  width: 300px;
  border-radius: 100%;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
